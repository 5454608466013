export default {
    locale: {
        current_language_name: 'Español',
    },
    pages: {
        blocklist: {
            title: 'Bloques',
            display_empty_blocks: 'Mostrar bloques vacíos',
        },
        blockpage: {
            block: 'Bloque',
            overview: 'Visión General',
            transactions: 'Transacciones',
        },
        transactions: {
            contract_deployment: 'Despliegue de contrato',
            deposit_action_name: 'Depósito',
            deposit_native: 'Depósito (nativo)',
            five_hundred_k_disclaimer: 'Mostrando los últimos 500 mil registros de { total }',
            for_block: 'para el bloque',
            for: 'para',
            gas_info_label: 'Información de gas',
            native_deposit_tooltip: 'Depósito desde Telos nativo',
            native_withdraw_tooltip: 'Retirar a Telos nativo',
            nonce_label: 'Nonce',
            see_more_details: 'Ver más detalles',
            see_tx_preview_tooltip: 'Ver vista previa de los detalles de la transacción',
            status_label: 'Estado',
            to: 'a',
            transaction_action_label: 'Acción de transacción',
            transaction_fee_label: 'Tarifa de transacción',
            transactions: 'Transacciones',
            transfer_for_x_tlos_from: 'Transferencia de { amount } TLOS desde',
            transfer_tlos_action_name: 'Transferir TLOS',
            withdraw_action_name: 'Retirar',
            withdraw_native: 'Retirar (nativo)',
            x_gas_used_of_y_limit: '{ amount } gas utilizado de un límite de { limit }',
        },
        transaction: {
            page_title: 'Detalles de la Transacción',
            overview: 'Resumen',
            logs: 'Registros',
            internal: 'Transacciones Internas',
            not_found: 'Transacción no encontrada',
        },
        internaltrx: {
            page_title: 'Transacciones internas del contrato',
            for_address: 'para la dirección { address }',
        },
        home: {
            telos_evm_explorer: 'El Explorador Telos EVM',
            market_cap: 'Capitalización de mercado',
            last_finalized_block: 'Último bloque finalizado',
            total_transactions: 'Transacciones totales',
            latest_blocks: 'Últimos Bloques',
            view_all_blocks: 'Ver todos los bloques',
            latest_transactions: 'Últimas Transacciones',
            view_all_transactions: 'Ver todas las transacciones',
        },
        error: {
            title: 'Página no encontrada.',
            subtitle: 'Por favor, revise nuevamente la URL. Si parece correcta, intente de nuevo más tarde.',
        },
        issuer: 'Issuer',
        account_not_found: 'No pudimos encontrar esta cuenta',
        approvals: 'Aprobaciones',
        supported_interfaces: 'Interfaces compatibles',
        explore_transactions: 'Explorar transacciones',
        recent_transactions: 'Transacciones recientes',
        rpc_endpoints: 'Puntos de entrada RPC',
        monitor: 'Monitor',
        go_home: 'Ir a inicio',
        minted: 'Acuñado',
        holders: 'Titulares',
        evm_holders: 'Todos los titulares de EVM de Telos',
        total_nfts_minted: 'Total de NFTs creados en esta colección',
        telos_supply: 'Suministro Telos EVM ',
        select_sol_file: 'Seleccione el archivo de contrato .sol para cargar',
        select_json_file: 'Seleccione el archivo de objeto de entrada JSON estándar para cargar',
        paste_contract_contents: 'debe seleccionar un archivo para cargar o alternar la entrada para pegar el contenido del contrato',
        contract_address: 'Dirección del contrato',
        enter_contract_address: 'Por favor ingrese la dirección del contrato \'0x0123...\'',
        invalid_address_format: 'formato de dirección no válido',
        compiler_version: 'Versión del compilador',
        select_compiler_version: 'seleccione la versión del compilador',
        eg_contracts: 'por ejemplo, \'contratos/\'',
        contract_file_directory_path: 'Ruta del directorio de archivos de contrato (dejar en blanco si no hay ninguno)',
        invalid_path_format: 'la ruta debe terminar con una barra inclinada /',
        upload_file: 'cargar archivo',
        text_input: 'entrada de texto',
        runs_value_for_optimization: 'Valor de ejecuciones para la optimización',
        constructor_arguments: 'Argumentos del constructor',
        comma_seperated_values: 'valores separados por comas, por ejemplo, Bob,123,0x12345...',
        no_trailing_commas: 'sin comas finales',
        paste_contract_code_here: 'copie y pegue el código del contrato aquí...',
        enter_contract_text: 'ingrese o pegue el texto del contrato',
        verify_contract: 'Verificar contrato',
        reset: 'Reiniciar',
        gas_used: 'Gas usado',
        account: 'Cuenta',
        contract: 'Contrato',
        transfers_title: 'Transferencias { type }',
        loading_transfers: 'Cargando transferencias',
        loading_approvals: 'Cargando aprobaciones de permisos',
        erc20_transfers: 'Transferencias ERC20',
        erc20_transfers_tooltip: 'Lista de transferencias de tokens ERC20 en esta transacción',
        erc721_transfers: 'Transferencias ERC721',
        erc721_transfers_tooltip: 'Lista de transferencias de tokens ERC721 en esta transacción',
        erc1155_transfers: 'Transferencias ERC1155',
        erc1155_transfers_tooltip: 'Lista de transferencias de tokens ERC1155 en esta transacción',
        tokens: 'Tokens',
        created_at_trx: 'Creada en la Trx',
        by_address: 'Por la dirección',
        number_used_once: 'Número usado una vez (nonce)',
        native_account: 'Cuenta nativa',
        balance: 'Balance',
        view_source_prompt: 'Este contrato ha sido verificado. Puede ver el código fuente y los metadatos en la pestaña \'contrato\'',
        account_url: '{ domain }/account/{ account }',
        tlos_balance: '{ balance } TLOS',
        couldnt_retreive_metadata_for_address: 'No se pudo recuperar los metadatos para { address }: { message }',
        transaction_details: 'Detalles de la transacción',
        transaction_not_found: 'No encontrado: { hash }',
        general: 'General',
        details: 'Detalles',
        logs: 'Registros',
        internal_txns: 'Transacciones internas',
        transaction_hash: 'Hash de transacción',
        click_to_change_date_format: 'haga click para cambiar el formato de fecha',
        block_number: 'Número de bloque',
        from: 'De',
        to: 'A',
        date: 'Fecha',
        success: 'Éxito',
        failure: 'Fracaso',
        status: 'Estado',
        balance_gwei: '{ amount } GWEI',
        balance_tlos: '{ amount } TLOS',
        error_message: 'Mensaje de error',
        contract_function: 'Función de contrato',
        function_parameters: 'Parámetros de función',
        deployed_contract: 'Contrato desplegado',
        click_to_show_in_wei: 'haga click para mostrar en wei',
        gas_price_charged: 'Precio del gas cargado',
        gas_fee: 'Tarifa de gas',
        gas_limit: 'Límite de gas',
        nonce: 'Nonce',
        input: 'Entrada',
        output: 'Salida',
        value: 'Valor',
        count: 'Cuenta',
        overview: 'visión general',
        more_info: 'más información',
        transaction_sent: 'txn enviado',
        first: 'primero',
        last: 'último',
        contract_creator: 'creador del contrato',
    },
    components: {
        gas_limit_tooltip: 'La cantidad máxima de gas que se puede gastar en esta transacción',
        gas_used_tooltip: 'La cantidad de gas que se gastó en esta transacción',
        known_tokens: 'Tokens conocidos',
        other_tokens: 'Tokens desconocidos',
        no_balances_found: 'No se encontró saldo de tokens ERC20 para esta dirección',
        internal_txns: 'Transacciones internas',
        n_internal_txns: '{ amount } transacciones internas',
        none: 'Ninguno',
        verify_prompt: 'Este contrato no ha sido verificado. ¿Te gustaría subir el(los) contrato(s) y los metadatos para verificar la fuente ahora?',
        verify_contract: 'Verificar contrato',
        search_evm_address_failed: 'La búsqueda de la dirección EVM vinculada a la cuenta nativa { accountName } falló. Puedes crear una en wallet.telos.net',
        unknown_web3_login_type: 'Tipo de inicio de sesión web3 desconocido: { provider }',
        unknown_evm_login_provider: 'Proveedor de inicio de sesión EVM desconocido: { provider }',
        unknown_native_login_provider: 'Proveedor de inicio de sesión nativo desconocido: { provider }',
        connect_wallet: 'Conectar billetera',
        view_address: 'Ver dirección',
        disconnect: 'Desconectar',
        disable_wallet_extensions: 'Deshabilita las billeteras de extensión o configura Brave Wallet como predeterminado en la configuración de billetera del navegador para usar Brave Wallet',
        enable_wallet_extensions: 'Habilita la extensión MetaMask y configura la opción predeterminada en `prefer extensions` en la configuración de billetera del navegador para usar la billetera MetaMask',
        evm_wallets: 'Billeteras EVM',
        advanced: 'Avanzado',
        continue_on_metamask: 'Continuar en Metamask',
        text1_native_wallets: 'Billeteras nativas para',
        text2_advanced_users: 'usuarios avanzados',
        text3_or_to_recover_assets: 'o para recuperar activos enviados a una dirección vinculada nativa',
        copy_to_clipboard: 'Copiar { text } al portapapeles',
        latest_block: 'Último bloque',
        price_sources: 'Datos obtenidos de Coingecko, CoinMarketCap o, si no están disponibles, de intercambios descentralizados en Telos EVM',
        marketcap_sources: 'Datos obtenidos de Coingecko o CoinMarketCap',
        usd_marketcap: 'Capitalización de mercado en USD',
        usd_price: 'Precio en USD',
        usd_value: 'Valor en USD',
        tlos_price: 'Precio de TLOS',
        gas_price: 'Precio del gas',
        gas_price_tlos: '(Precio del gas * Gas usado) en TLOS',
        gas_price_gwei: 'Precio del gas en gwei',
        click_to_expand: 'haga click para expandir',
        click_to_fold: 'haga click para plegar',
        expand_all: 'Expandir todo',
        collapse_all: 'Colapsar todo',
        search_evm_failed: 'La búsqueda de la dirección EVM vinculada a la cuenta nativa { search_term } falló.',
        search_failed: 'La búsqueda falló, por favor ingrese un término de búsqueda válido.',
        add_to_metamask: 'Agregar { symbol } a MetaMask',
        tx_hash: 'Hash de la transacción',
        direction: 'Dirección',
        block: 'Bloque',
        date: 'Fecha y hora (UTC)',
        age: 'Antigüedad',
        method: 'Método',
        to_interacted_with: 'Para',
        value: 'Valor',
        txn_fee: 'Txn Fee',
        txn_failed: 'Txn Fallida',
        token: 'Token',
        unknown_precision: 'Precisión desconocida',
        click_to_change_format: 'haga click para cambiar el formato',
        balance: 'Balance',
        error_fetching_balance: 'error al recuperar el saldo',
        launch_metamask_dialog_to_add: 'Lanzar diálogo MetaMask para agregar { symbol }',
        search_hints: 'Transacción,Dirección,Bloque',
        no_provider_found: 'Se encontró más de un proveedor, desactive los proveedores adicionales o el proveedor actual de la billetera EVM no es compatible.',
        copied: 'Copiado',
        copy_to_clipboard_failed: 'Error al copiar al portapapeles',
        gwei: 'Gwei',
        failed_to_fetch_transactions: 'Failed to fetch transactions.',
        failed_to_parse_transaction: 'No se pudo analizar los datos de la transacción, el error fue: { message }',
        executed_based_on_decoded_data: 'Name of the function executed based. For unidentified functions, method ID is displayed instead.',
        unsupported_token_type: 'Tipo de token no compatible: { tokenType }',
        token_id: 'Id #{ tokenId }',
        success: 'Éxito',
        nonce: 'Nonce',
        from: 'De',
        to: 'A',
        item: 'Ítem',
        amount: 'Cantidad',
        download_image: 'Descargar imagen',
        confirm_download_image: 'Confirma que quieres descargar esta imagen',
        confirm: 'Confirmar',
        click_to_toggle_media_size: 'haga click para cambiar el tamaño del medio',
        toggle_expand: 'Alternar ajuste a pantalla o tamaño original',
        close: 'Cerrar',
        input_viewer: {
            name: 'Nombre',
            type: 'Tipo',
            data: 'Datos',
            default_view: 'Vista predeterminada',
            original_view: 'Vista original',
            decoded_view: 'Vista decodificada',
            copy_button_desc: 'tabla de parámetros',
        },
        approvals: {
            token_id: 'Token ID',
            approved: 'Aprobado',
            approvals_granted_title: 'Aprobaciones ERC:',
            login_account: 'Inicie sesión en esta cuenta usando su billetera para poder ver sus aprobaciones.',
            approval_text: 'Se necesita una firma de su billetera para actualizar el monto de las aprobaciones seleccionadas a 0.',
            type: 'Tipo',
            delete: 'Borrar',
            delete_all: 'Eliminar todos',
            unselect_all: 'Deselecciona todo',
            cancel: 'Anular',
            remove_approval: 'Bórralo',
            removal_approval: 'Eliminar aprobación única',
            unselect_all_approvals: 'Deseleccionar todas las aprobaciones',
            infinite: 'Infinita',
            infinite_tooltip: 'El límite de la aprobación (del permiso) es mayor que la cantidad total de este token',
            select: 'Seleccionar aprobación',
            unselect: 'Deseleccionar aprobación',
            update: 'Actualización de aprobación',
            update_description: 'Ingrese la nueva cantidad para permitir al gastador. Esto requerirá una firma de billetera.',
            removal_approvals: 'Eliminar todas las aprobaciones',
            removal_selected_approvals: 'Eliminar todas las aprobaciones seleccionadas',
            update_failed: 'Error al actualizar la aprobación',
            update_success: 'La aprobación del permiso a {spender} para el contrato {contract} se actualizó con éxito',
            spender: 'Gastador',
            amount: 'Cantidad',
        },
        nfts: {
            show_without_metadata: 'Mostrar NFTs sin metadatos',
            id: 'ID del Token',
            amount: 'Cantidad',
            name: 'Nombre',
            collection: 'Colección',
            nfts: 'NFTs',
            metadata: 'Meta',
            minter: 'Creador',
            owner: 'Propietario',
            contract: 'Contrato',
            image: 'Imagen',
            media: 'Medios',
            minted: 'Bloque acuñado',
            attributes: 'atributo',
            ipfs: 'Obtener de IPFS',
            consult_metadata: 'Consultar metadatos',
            consult_media: 'Consultar medios',
            consult_collection: 'Consultar colección',
        },
        holders : {
            show_system_contracts: 'Mostrar contratos del sistema',
            holder: 'Poseedor',
            balance: 'Balance',
            global_supply: 'Suministro mundial',
            telos_supply: 'Suministro Telos EVM',
            updated: 'Ultima actualización',
        },
        transaction: {
            in: 'entra',
            out: 'sale',
            self: 'queda',
            load_error: 'No se pudieron cargar las transacciones',
            show_short: 'Mostrar corto',
            show_total: 'Mostrar total',
            show_wei: 'Mostrar wei',
            value_uint256: 'Valor (uint256) : ',
            tlos_transfer: 'Transferencia TLOS',
            native_deposit: 'Depósito desde Telos Nativo',
            native_withdraw: 'Retiro a Telos Nativo',
            unknown: 'Desconocido',
            contract_deployed: 'Contrato Desplegado',
            contract_deployment: 'Despliegue de Contrato',
            contract_interaction: 'Interacción de Contrato',
            deposit: 'depósito',
            withdraw: 'retiro',
            no_internal_trxs_found: 'No se encontraron transacciones internas',
            human_readable: 'Legible por Humanos',
            no_logs_found: 'No se encontraron registros',
            verify_related_contract: 'Verifique el contrato relacionado para cada registro para ver su versión legible por humanos',
            failed_to_retrieve_contract: 'No se pudo recuperar el contrato con la dirección { address }',
            trx_hash: 'Hash de la Transacción',
            trx_hash_tooltip: 'El identificador único de la transacción.',
            block: 'Bloque',
            block_tooltip: 'El número de bloque en el que se incluyó esta transacción.',
            status: 'Estado',
            status_tooltip: 'Indica si la transacción fue exitosa, fallida o está pendiente',
            timestamp: 'Marca de tiempo',
            timestamp_tooltip: 'La hora y fecha en que se confirmó la transacción.',
            trx_action: 'Acción de la Transacción',
            trx_action_tooltip: 'La operación realizada por esta transacción, como una transferencia o ejecución de contrato.',
            from: 'Desde',
            from_tooltip: 'La dirección que inicia la transacción.',
            to: 'Hacia',
            to_tooltip: 'La dirección de destino de la transacción.',
            erc20_transfers: 'Transferencias ERC20',
            erc20_transfers_tooltip: 'Lista de transferencias de tokens ERC20 en esta transacción',
            erc721_transfers: 'Transferencias ERC721',
            erc721_transfers_tooltip: 'Lista de transferencias de tokens ERC721 en esta transacción',
            erc1155_transfers: 'Transferencias ERC1155',
            erc1155_transfers_tooltip: 'Lista de transferencias de tokens ERC1155 en esta transacción',
            tlos_transfers: 'Transferencias TLOS',
            tlos_transfers_tooltip: 'Lista de transferencias internas de TLOS en esta transacción',
            value: 'Valor',
            value_tooltip: 'La cantidad de criptomoneda transferida en esta transacción.',
            gas_fee: 'Tarifa de Transacción',
            gas_fee_tooltip: 'La tarifa total pagada por esta transacción, en TLOS y USD.',
            gas_price: 'Precio del Gas',
            gas_price_tooltip: 'El precio por unidad de gas pagado por la transacción.',
            more_details: 'Más Detalles',
            show_more_details: 'haga click para mostrar más',
            show_less_details: 'haga click para mostrar menos',
            gas_limit_n_usage: 'Límite de Gas y Uso por Txn',
            gas_limit_n_usage_tooltip: 'La cantidad máxima de gas permitida para la transacción y la cantidad real de gas utilizada.',
            nonce: 'Nonce',
            nonce_tooltip: 'Un número secuencial asociado a cada transacción realizada por la misma dirección, asegurando que las transacciones se procesen en orden.',
            position_in_block: 'Posición en el Bloque',
            position_in_block_tooltip: 'La posición de esta transacción en el bloque.',
            input: 'Entrada',
            input_tooltip: 'Datos enviados junto con la transacción, típicamente usados para interacciones con contratos.',
        },
        inputs: {
            incorrect_address_array_length: 'Debe haber { size } direcciones en la matriz',
            incorrect_booleans_array_length: 'Debe haber { size } booleanos en la matriz',
            incorrect_bytes_array_length: 'Debe haber { size } bytes en la matriz',
            odd_number_of_bytes: 'Debe haber un número par de caracteres de bytes',
            incorrect_sigint_array_length: 'Debe haber { size } enteros firmados en la matriz',
            incorrect_strings_array_length: 'Debe haber { size } cadenas en la matriz',
            incorrect_unsigint_array_length: 'Debe haber { size } enteros sin firmar en la matriz',
            invalid_address_array_string: 'El valor ingresado no representa una matriz de direcciones',
            invalid_booleans_array_string: 'El valor ingresado no representa una matriz de bool',
            invalid_bytes_array_string: 'El valor ingresado no representa una matriz de bytes',
            invalid_sigint_array_string: 'El valor ingresado no representa una matriz de enteros firmados',
            invalid_strings_array_string: 'El valor ingresado no representa una matriz de cadenas',
            invalid_unsigint_array_string: 'El valor ingresado no representa una matriz de enteros sin firmar',
            invalid_address_length: 'Una dirección debe tener exactamente 40 caracteres, sin incluir "0x"',
            invalid_address_start: 'Una dirección debe comenzar con 0x',
            invalid_address_characters: 'La entrada contiene caracteres no válidos',
            readonly: 'Este campo es de solo lectura',
            required: 'Este campo es obligatorio',
            too_large: 'El valor máximo para int{ size } es { max }',
            too_small: 'El valor mínimo para int{ size } es { max }',
            too_large_pow2: 'El valor máximo para uint{ size } es 2^{ size } - 1',
            too_small_pow2: 'El valor mínimo para int{ size } es -(2^{ size }) + 1',
            too_large_unsigint: 'El valor máximo para uint{ size } es 2^{ size } - 1',
            no_negative_unsigint: 'El valor para uint{ size } no debe ser negativo',
            invalid_signed_integer: 'Entero firmado no válido',
            invalid_unsigint: 'La entrada debe ser un entero sin firmar válido',
            str_input_placeholder: '["algún valor", ... , "valor final"]',
            str_input_hint: 'Las comillas dobles en las cadenas deben escaparse (\\")',
            address_placeholder: 'Dirección que comienza con 0x',
            address_label: '{ label } (address)',
            boolean_array_label: '{ label } (bool[{ size }])',
        },
        health: {
            status: 'Estado',
            checked_at: 'Verificado en',
            task: 'Tarea',
            message: 'Mensaje',
            category: 'Category',
            block_height: 'Altura del bloque',
            latency: 'Latencia',
            success: 'Success',
            info: 'Info',
            alert: 'Alert',
            error: 'Error',
            click_to_change_format: 'haga click para cambiar el formato',
        },
        contract_tab: {
            abi_loaded_from_interface: 'Esta ABI genérica se cargó utilizando supportsInterface() de IEP-165 y puede no ser exhaustiva. Algunas de las funciones mostradas podrían no estar implementadas. Verifica el contrato para poder interactuar con él utilizando su ABI completo.',
            copy_abi_to_clipboard: 'Copiar el JSON del ABI del contrato al portapapeles',
            enter_amount: 'Seleccione el número de decimales y escriba una cantidad, esto se ingresará para usted en el parámetro de función como uint256',
            result: 'Resultado',
            view_transaction: 'Ver transacción',
            code: 'Código',
            bytecode: 'Código de bytes',
            read: 'Leer',
            write: 'Escribir',
            amount: 'Cantidad',
            value: 'Valor',
            custom_decimals: 'Decimales personalizados',
            custom: 'Personalizado',
            unverified_contract_source: 'Este contrato no está verificado.',
            verified_contract_source: 'Verifica y Publica el código fuente de tu contrato',
            here: 'aquí.',
            upload_source_files: 'para subir los archivos fuente, lo que también verificará este contrato.',
            abi_autoloaded: 'Mientras tanto, puedes interactuar con el contrato utilizando nuestra ABI preestablecida cargada automáticamente.',
            choose_abi: 'Alternativamente, puedes interactuar con el contrato usando una de nuestras ABIs preestablecidas o una personalizada en JSON:',
            use_erc20_abi: 'Usar ABI ERC20',
            use_erc721_abi: 'Usar ABI ERC721',
            use_erc1155_abi: 'Usar ABI ERC1155',
            abi_from_json: 'ABI desde JSON',
            paste_abi_json_here: 'Pegue el JSON ABI aquí',
            abi_json_preview: 'Vista previa del JSON ABI',
            provided_abi_invalid: 'ABI proporcionado no válido',
            provided_json_invalid: 'JSON proporcionado no válido',
            read_functions: 'Funciones de lectura',
            write_functions: 'Funciones de escritura',
            unverified_contract: 'Contrato no verificado',
            verified_contract: 'Contrato verificado',
            upload_abi_json: 'Subir archivo JSON de ABI',
            discard_abi_json: 'Descartar JSON de ABI actual',
        },
        header: {
            address_copied: 'Dirección copiada al portapapeles',
            address_not_found: 'La búsqueda de la dirección EVM vinculada a la cuenta nativa { account } falló.',
            api_documentation: 'Documentación de la API',
            blockchain: 'Blockchain',
            blocks: 'Bloques',
            connect_wallet: 'Conectar Cartera',
            copy_address: 'Copiar dirección',
            csv_export: 'Exportar CSV',
            developers: 'Desarrolladores',
            disconnect_wallet_tooltip: 'Desconectar cartera',
            gas: 'Gas',
            goto_address_details: 'Ir a los detalles de la dirección',
            health_monitor: 'Monitoreo de salud',
            home: 'Inicio',
            more: 'Más',
            network: 'Red',
            open_language_switcher: 'Abrir el selector de idioma',
            search_failed: 'La búsqueda falló, ingrese un término de búsqueda válido.',
            search_placeholder: 'Busca cualquier cosa en Telos',
            select_language: 'Seleccionar idioma',
            switch_to_dark_theme: 'Cambiar a tema oscuro',
            switch_to_light_theme: 'Cambiar a tema claro',
            system_token_price: 'Precio de {token}',
            telos_ecosystem: 'Ecosistema Telos',
            telos_zero_explorer: 'Explorador Telos Zero',
            telos_evm_logo_alt: 'Logotipo de Telos EVM',
            telos_wallet: 'Wallet',
            telos_bridge: 'Bridge',
            transactions: 'Transacciones',
            internal_transactions: 'Transacciones internas',
            verify_contract_sourcify: 'Verificar Contrato (Sourcify)',
            view_other_networks: 'Ver otras redes',
        },
        blocks: {
            block_height: 'Altura del Bloque',
            timestamp: 'Marca de Tiempo',
            size: 'Tamaño',
            gas_used: 'Gas Utilizado',
            gas_limit: 'Límite de Gas',
            hash: 'Hash',
            parent_hash: 'Hash Padre',
            nonce: 'Nonce',
            extra_data: 'Datos Extra',
            extra_data_tooltip: 'Datos adicionales incluidos en el bloque',
            block_height_tooltip: 'También llamado "Número de Bloque". La altura del bloque representa la longitud de la blockchain, incrementando en uno después de la adición de un nuevo bloque',
            timestamp_tooltip: 'El momento y fecha en que el bloque fue producido',
            transactions_tooltip: 'El número de transacciones en el bloque. Las transacciones internas son transacciones que ocurren como resultado de la ejecución de un contrato e incluyen un valor TLOS',
            size_tooltip: 'la cantidad de datos en el bloque, determinada por el límite de gas',
            gas_used_tooltip: 'La cantidad de gas utilizado en el bloque',
            gas_limit_tooltip: 'El límite de gas para todas las transacciones en el bloque',
            nonce_tooltip: 'El valor utilizado durante la minería para lograr un consenso sobre la Prueba de Trabajo para el bloque',
            hash_tooltip: 'El hash del encabezado del bloque',
            parent_hash_tooltip: 'El hash del bloque anterior',
            count_transactions: '{count} transacciones',
            count_transaction: '1 transacción',
            in_this_block: 'en este bloque',
            transactions: 'Transacciones',
            transactions_root: 'Raíz de Transacciones',
            block: 'Bloque',
            age: 'Edad',
        },
        footer: {
            telos: 'Telos',
            telos_homepage: 'Página Principal',
            telos_wallet: 'Telos Wallet',
            telos_bridge: 'Telos Bridge',
            telos_zero_explorer: 'Explorador Telos Zero',
            buy_telos: 'Comprar Telos',
            stake_telos: 'Depositar Telos',
            telos_ecosystem: 'Ecosistema de Telos',
            network_status: 'Estado de la Red',
            about: 'Acerca de',
            about_us: 'Acerca de Nosotros',
            contactUs: 'Contáctanos',
            build: 'Construir',
            api_documentation: 'Documentación de API',
            telos_documentation: 'Documentación de Telos',
            github: 'Github',
            brand_assets: 'Activos de Marca',
            back_to_top: 'Volver arriba',
        },
        export: {
            block_range: 'Rango de bloques',
            choose_download_option: 'Elija una opción de descarga',
            column_header_action: 'Acción',
            column_header_amount: 'Cantidad',
            column_header_block_number: 'Número de bloque',
            column_header_contract_address: 'Dirección del contrato',
            column_header_date: 'Fecha',
            column_header_from: 'De',
            column_header_nft_collection_name: 'Nombre de la colección NFT',
            column_header_nft_id: 'ID de NFT',
            column_header_timestamp: 'Marca de tiempo de Unix (ms)',
            column_header_to: 'A',
            column_header_token_contract_address: 'Dirección del contrato del token',
            column_header_token_name: 'Nombre del token',
            column_header_token_symbol: 'Símbolo del token',
            column_header_tx_hash: 'Hash de transacción',
            date_range: 'Rango de fechas',
            start_date: 'Fecha de inicio',
            end_date: 'Fecha de finalización',
            invalid_range: 'Rango de fechas no válido',
            download_csv: 'Descargar CSV',
            download_transactions_csv: 'Descargar transacciones CSV',
            download_erc_20_transfers_csv: 'Descargar transferencias ERC-20 CSV',
            download_erc_721_transfers_csv: 'Descargar transferencias ERC-721 CSV',
            download_erc_1155_transfers_csv: 'Descargar transferencias ERC-1155 CSV',
            end_block: 'Bloque final',
            erc_1155_transfers: 'Transferencias ERC-1155',
            erc_20_transfers: 'Transferencias ERC-20',
            erc_721_transfers: 'Transferencias ERC-721',
            export_data: 'Exportar datos',
            export_type: 'Tipo de exportación',
            limit_notice: 'Sólo se exportarán los primeros {amount} resultados.',
            notification_successful_download: '¡Descarga exitosa!',
            notification_failed_download: 'Se produjo un error al descargar datos.',
            page_header: 'Descargar datos (exportación CSV)',
            reset: 'Reiniciar',
            start_block: 'Bloque de inicio',
            transactions: 'Transacciones',
        },
    },
    evm_wallet: {
        send_icon_alt: 'Ícono de envío',
        receive_icon_alt: 'Ícono de recepción',
        buy_icon_alt: 'Ícono de compra de más tokens',
        token_logo_alt: 'Logo del token',
        send: 'Enviar',
        receive: 'Recibir',
        scan_qr: 'Escanea el código QR para obtener tu cuenta',
        buy: 'Comprar',
        unwrap: 'Desenvolver',
        wrap: 'Envolver',
        stake: 'Apostar',
        unstake: 'Desapostar',
        copy: 'Copiar',
        link_to_send_aria: 'Enlace a la página de envío',
        link_to_receive_aria: 'Enlace a la página de recepción',
        link_to_buy_aria: 'Enlace externo para comprar tokens',
        balance_row_actions_aria: 'Acciones de la fila de saldo',
        no_fiat_value: 'No se encontró un valor fiduciario confiable',
        receiving_account: 'Cuenta receptora',
        account_required: 'Se requiere una cuenta',
        token: 'Token',
        cancel: 'Cancelar',
        estimated_fees: 'Tarifas estimadas',
        amount_available: '{amount} disponible',
        amount_required: 'Se requiere una cantidad',
        view_contract: 'Ver contrato',
        invalid_form: 'Hay al menos un campo inválido.',
        general_error: 'Hubo un error al procesar tu solicitud',
        invalid_amount_precision: 'Solo puedes ingresar {precision} decimales',
        sent: 'Enviado',
        received: 'Recibido',
        swapped: 'Intercambiado',
        switch: 'Cambiar',
        failed_contract_interaction: 'Interacción con el contrato fallida',
        contract_interaction: 'Interacción con el contrato',
        contract_creation: 'Creación de contrato',
        aria_link_to_address: 'Enlace a la página de dirección del explorador de bloques',
        aria_link_to_transaction: 'Enlace a la página de transacción del explorador de bloques',
        click_to_fill_max: 'Haz clic para llenar el monto máximo',
        amount_exceeds_available_balance: 'Saldo insuficiente',
        incorrect_network: '¡Red incorrecta detectada! Cambia a {networkName} para completar la transacción',
        viewing_n_transactions: 'Viendo {rowsPerPage} de {totalRows} transacciones',
        no_transactions_found: 'No se encontraron transacciones',
        add_to_metamask: 'Agregar a MetaMask',
        rejected_metamask_prompt: 'El mensaje de MetaMask fue rechazado',
        error_adding_token_to_metamask: 'Error al agregar el token a MetaMask',
        inventory: 'Coleccionables',
    },
    global: {
        all: 'todo',
        true: 'verdadero',
        false: 'falso',
        close: 'Cerrar',
        toggle_fullscreen: 'Cambiar a modo pantalla completa',
        name: 'Nombre',
        symbol: 'Símbolo',
        show_table: 'Mostrar tabla',
        show_grid: 'Mostrar cuadrícula',
        records_per_page: 'Registros por página:',
        max_decimals_reached: 'Por favor, usa un máximo de {max} decimales',
        erc20_token: 'Token ERC20',
        erc721_token: 'Token ERC721',
        sign: 'Firmar',
        hours: 'hora(s)',
        updated: 'Última actualización',
        action: 'Acción',
        wallet_response: 'Esperando respuesta de tu proveedor de cartera',
        minutes: 'minuto(s)',
        days: 'día(s)',
        and: 'y',
        unknown: 'Desconocido',
        data_behind_by: '¡Lo siento! Los datos mostrados están atrasados por',
        not_synced: 'El indexador no está sincronizado con el último bloque',
        try_reloading: 'Puedes intentar recargar la página para solucionarlo. Si el problema persiste, contacta a un miembro del equipo de Telos.',
        error: 'Error',
        async_error_description: '¡Lo siento! Ocurrió un error al intentar solicitar recursos. Por favor, asegúrate de que tu conexión a internet esté funcionando y vuelve a intentarlo. Si el error persiste, contáctanos directamente en nuestro canal de Telegram.',
        language: 'Idioma',
        cancel: 'Cancelar',
        ok: 'Aceptar',
        dismiss: 'Descartar',
        empty_block: 'Bloque vacío',
        empty_block_description: 'Este bloque no tiene transacciones. Intenta navegar a un bloque diferente',
        internal_error: 'Error Interno',
    },
    layouts: {
        health_status: 'Estado de la salud',
        stake_telos: 'Stake TLOS',
        teloscan_mainnet: 'Teloscan Mainnet',
        teloscan_testnet: 'Teloscan Testnet',
    },
    notification: {
        success_title_trx: 'Éxito',
        success_title_copied: 'Copiado',
        success_message_trx: 'Tu transacción ha sido procesada. Para obtener información detallada, haz clic en el enlace de abajo.',
        success_message_revoking: 'Revocar la asignación de <b>{symbol}</b> para <b>{address}</b> se realizó con éxito.',
        success_message_copied: 'El nombre de tu cuenta se ha copiado al portapapeles.',
        success_see_trx_label: 'Ver Transacción',
        dismiss_label: 'Descartar',
        error_title: '¡Ups!',
        error_title_disconnect: 'Sin conexión a Internet',
        error_message_disconnect: 'Lo sentimos, parece que no estás conectado a Internet. Por favor, verifica tu conexión de red e inténtalo de nuevo.',
        error_see_details_label: 'Ver Detalles',
        error_details_title: 'Detalles del Error',
        neutral_message_sending: 'Enviando <b>{quantity}</b> a <b>{address}</b>',
        neutral_message_revoking: 'Revocando asignación de <b>{symbol}</b> para <b>{address}</b>',
        neutral_message_wrapping: 'Convirtiendo <b>{quantity} {symbol}</b> a ERC20',
        neutral_message_unwrapping: 'Restaurando <b>{quantity} {symbol}</b>',
        neutral_message_withdrawing: 'Retirando <b>{quantity} {symbol}</b>',
        dont_show_message_again: 'No volver a mostrar este mensaje',
        error_message_sending: 'Se produjo un error al enviar <b>{quantity}</b> a <b>{address}</b>',
        error_message_revoking: 'Se produjo un error al revocar la asignación de <b>{symbol}</b> para <b>{address}</b>',
        error_message_wrapping: 'Se produjo un error al convertir <b>{quantity} {symbol}</b> a ERC20',
        error_message_unwrapping: 'Se produjo un error al restaurar <b>{quantity} {symbol}</b>',
        error_message_withdrawing: 'Se produjo un error al retirar <b>{quantity} {symbol}</b>',
        error_message_custom_call: 'Se produjo un error al llamar a <b>{name}</b> con <b>{params} parámetros</b>',
        error_message_custom_call_send: 'Se produjo un error al llamar a <b>{name}</b> con <b>{params} parámetros</b> y enviar <b>{quantity} {symbol}</b>',
        neutral_message_custom_call: 'llamando a <b>{name}</b> con <b>{params} parámetros</b>',
        neutral_message_custom_call_send: 'llamando a <b>{name}</b> con <b>{params} parámetros</b> y enviando <b>{quantity} {symbol}</b>',
    },
    antelope: {
        contracts: {
            invalid_contract: 'Contrato o ABI del contrato faltante',
            contract_data_required: 'Datos del contrato faltantes',
        },
        evm: {
            error_support_provider_request: 'El proveedor no soporta el método de solicitud',
            error_login: 'Error en el proceso de inicio de sesión',
            error_add_chain_rejected: 'El usuario ha rechazado la solicitud para agregar la cadena',
            error_connect_rejected: 'El usuario ha rechazado la solicitud para conectarse a la cadena',
            error_add_chain: 'Error al agregar la cadena',
            error_switch_chain_rejected: 'El usuario ha rechazado la solicitud para cambiar la cadena',
            error_switch_chain: 'Error al cambiar la cadena',
            error_no_provider: 'No se encontró proveedor',
            error_getting_function_interface: 'Error al intentar encontrar la firma del evento para la función {prefix}',
            error_getting_event_interface: 'Error al intentar encontrar la firma del evento para el evento {hex}',
            error_invalid_address: 'Dirección inválida',
            error_getting_contract_creation: 'Error al intentar obtener la información de creación del contrato',
            error_no_signer: 'No se encontró firmante',
            error_send_transaction: 'Ocurrió un error desconocido al enviar la transacción',
            error_transfer_failed: 'Ocurrió un error desconocido al transferir tokens',
            error_call_exception: 'Ocurrió un error al llamar a la función del contrato inteligente',
            error_insufficient_funds: 'No tienes suficientes fondos para completar esta transacción',
            error_missing_new: 'Faltaba un nuevo operador al desplegar el contrato inteligente',
            error_nonce_expired: 'El nonce utilizado para esta transacción es demasiado bajo',
            error_numeric_fault: 'Falló una operación numérica',
            error_replacement_underpriced: 'Una nueva transacción que reemplaza una existente no tiene suficiente precio de gas',
            error_transaction_replaced: 'Esta transacción fue reemplazada por otra',
            error_unpredictable_gas_limit: 'No se pudo estimar el límite de gas para esta transacción',
            error_user_rejected: 'Rechazaste la transacción',
            error_transaction_canceled: 'Cancelaste la acción',
            error_wrap_not_supported_on_native: 'El wrapping no es soportado en la cadena nativa',
            error_unwrap_not_supported_on_native: 'El unwrapping no es soportado en la cadena nativa',
            error_wrap_failed: 'Ocurrió un error desconocido al envolver tokens',
            error_unwrap_failed: 'Ocurrió un error desconocido al desenvolver tokens',
            error_withdraw_failed: 'Ocurrió un error desconocido al retirar tokens',
            error_fetching_token_price: 'Ocurrió un error desconocido al obtener los datos del precio del token',
        },
        history: {
            error_fetching_transactions: 'Error inesperado al obtener las transacciones. Por favor, actualiza la página para intentarlo de nuevo.',
            error_fetching_nft_transfers: 'Error inesperado al obtener las transferencias de NFT. Por favor, actualiza la página para intentarlo de nuevo.',
        },
        chain: {
            error_update_data: 'Error al actualizar los datos',
            error_apy: 'Error al obtener el APY',
            error_invalid_network: 'Red inválida',
            error_no_default_authenticator: 'No se encontró autenticador predeterminado',
        },
        account: {
            error_login_native: 'Se ha producido un error al intentar iniciar sesión en la cadena nativa',
            error_login_evm: 'Se ha producido un error al intentar iniciar sesión en la cadena EVM',
            error_auto_login: 'Se ha producido un error al intentar iniciar sesión automáticamente',
        },
        utils: {
            error_parsing_transaction: 'Error al analizar los datos de la transacción',
            error_contract_instance: 'Error al crear la instancia del contrato',
            error_parsing_log_event: 'Error al analizar el evento de registro',
        },
        balances: {
            error_at_transfer_tokens: 'Se ha producido un error al intentar transferir tokens',
            error_token_contract_not_found: 'No se encontró el contrato del token para la dirección {address}',
        },
        rex: {
            error_contract_not_found: 'No se encontró el contrato para la dirección {address}',
        },
        wallets: {
            error_system_token_transfer_config: 'Error al obtener la configuración de transferencia de tokens del sistema Wagmi',
            error_token_transfer_config: 'Error al obtener la configuración de transferencia de tokens Wagmi',
            error_oreid_no_chain_account: 'La aplicación {appName} no tiene una cuenta de cadena para la cadena {networkName}',
            network_switch_success: 'Red cambiada con éxito',
        },
        wrap: {
            error_getting_wrapped_contract: 'Se produjo un error al obtener el contrato del token del sistema envuelto',
            error_wrap: 'Se produjo un error desconocido al envolver el token del sistema',
        },
        words: {
            day: 'día',
            days: 'días',
            hour: 'hora',
            hours: 'horas',
            minute: 'minuto',
            minutes: 'minutos',
            second: 'segundo',
            seconds: 'segundos',
            time_ago: 'Hace {time}',
        },
    },
};
